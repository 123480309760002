import service from './network';

export function queryStreetRealTime(data) {
    return service.request({
        url: '/disposal/site/street',
        method: 'post',
        data
    })
}

export function queryStreetAnalyze(data) {
    return service.request({
        url: '/disposal/site/street',
        method: 'post',
        data
    })
}

/**
 *街道分析-今日
 * @param {} data
 * @returns
 */
export function queryStreetTodayAnalyzeApi(data, code) {
    return service.request({
        url: '/street/center/trendToDay',
        method: 'post',
        data,
        headers: {'moduleToken': code}
    })
}

/**
 *街道分析-本周
 * @param {} data
 * @returns
 */
export function queryStreetWeekAnalyzeApi(data, code) {
    return service.request({
        url: '/street/center/trendToWeek',
        method: 'post',
        data,
        headers: {'moduleToken': code}
    })
}

/**
 *街道分析-本月
 * @param {} data
 * @returns
 */
export function queryStreetMonthAnalyzeApi(data, code) {
    return service.request({
        url: '/street/center/trendToMonth',
        method: 'post',
        data,
        headers: {'moduleToken': code}
    })
}
