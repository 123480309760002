<template>
  <div class="content">
    <a-form layout="inline">
      <a-form-item label="街道名称">
        <a-input v-model="query.streetName" placeholder="请输入街道名称"/>
      </a-form-item>

      <a-form-item label="扫描时间">
        <a-range-picker v-model="query.time"/>
      </a-form-item>

      <a-form-item>
        <a-button type="primary" @click="customQuery">查询</a-button>
      </a-form-item>
    </a-form>

    <a-table :columns="columns" :data-source="data" :loading="loading"
             :pagination="pagination"
             style="margin-top: 24px;"
             @change="handleTableChange">

      <span slot="action" slot-scope="text, record">
          <a-button type="primary" @click="$notification['warning']({ message: '敬请期待'})">详情</a-button>
        </span>
    </a-table>
  </div>
</template>

<script>
import {queryStreetAnalyze} from "@/api/street";
import _ from 'lodash'
import {formatToDateBegin, formatToDateEnd, formatToDateWithTime, getPreviewDate} from "@/utils/time";
import {streetTrashName} from "@/common/config";
import {parseNumberWith2Fixed} from "@/utils/transition";
import {disposalSiteTrashName} from "../../common/config";

export default {
  name: "streetAnalyze",
  data() {
    return {
      query: {
        streetName: undefined,
        time: [],

        sort: undefined,
        order: undefined
      },
      pagination: {
        pageSize: 10,
        current: 1,
        total: 0,
        showTotal: (total, range) => `总共 ${total} 条数据`,
      },
      columns: [
        {
          title: '街道名称',
          dataIndex: 'streetName',
          key: 'streetName',
          scopedSlots: {customRender: 'streetName'},
          align: 'center',
          // sorter: true,
        },
        {
          title: '累计载入（KG）', align: 'center',
          children: [
            {
              title: '厨余垃圾',
              dataIndex: 'kitchenWaste',
              key: 'kitchenWaste',
              scopedSlots: {customRender: 'kitchenWaste'},
              align: 'center',
            },
            {
              title: '其他垃圾',
              dataIndex: 'other',
              key: 'other',
              scopedSlots: {customRender: 'other'},
              align: 'center',
            },
            {
              title: '可回收物',
              dataIndex: 'recyclable',
              key: 'recyclable',
              scopedSlots: {customRender: 'recyclable'},
              align: 'center',
            },
            {
              title: '有害垃圾',
              dataIndex: 'harmful',
              key: 'harmful',
              scopedSlots: {customRender: 'harmful'},
              align: 'center',
            },
            {
              title: '园林绿化垃圾',
              dataIndex: 'landscaping',
              key: 'landscaping',
              scopedSlots: {customRender: 'landscaping'},
              align: 'center',
            },
            {
              title: '大件垃圾',
              dataIndex: 'bulkyWaste',
              key: 'bulkyWaste',
              scopedSlots: {customRender: 'bulkyWaste'},
              align: 'center',
            },
            {
              title: '建筑垃圾',
              dataIndex: 'construct',
              key: 'construct',
              scopedSlots: {customRender: 'construct'},
              align: 'center',
            },
          ]
        },
        {
          title: '累计载出（KG）', align: 'center',
          children: [
            {
              title: '加工颗粒',
              dataIndex: 'grain',
              key: 'grain',
              scopedSlots: {customRender: 'grain'},
              align: 'center',
            },
          ]
        },
        {
          title: '总重（KG）',
          dataIndex: 'total',
          key: 'total',
          scopedSlots: {customRender: 'total'},
          align: 'center',
        },
        // {
        //   title: '操作',
        //   key: 'action',
        //   scopedSlots: {customRender: 'action'},
        //   align: 'center'
        // },
      ],
      data: [],
      loading: true,
    }
  },
  mounted() {
    this.queryStreetList()
  },
  methods: {
    async queryStreetList() {
      this.loading = true

      let data = await queryStreetAnalyze(this.convertQuery())

      this.convertPagination(data.result)

      this.data = this.convertData(data.result.content)

      this.loading = false
    },
    handleTableChange(pagination, filters, sorter) {
      this.pagination.pageSize = pagination.pageSize
      this.pagination.current = pagination.current
      this.query.sort = sorter.field
      this.query.order = sorter.order

      this.queryStreetList()
    },
    customQuery() {
      this.pagination.current = 1
      this.queryStreetList()
    },
    convertQuery() {
      return {
        streetName: this.query.streetName,
        startTime: formatToDateBegin(this.query.time[0]),
        endTime: formatToDateEnd(this.query.time[1]),

        pageSize: this.pagination.pageSize,
        pageNo: this.pagination.current,
        sort: this.query.sort,
        order: this.query.order,
      }
    },
    convertPagination(data) {
      this.pagination.current = data.pageNo + 1
      this.pagination.total = data.totalElements
    },
    convertData(data) {
      return _.map(data, (street, index) => {
        let tmp = {
          ...street,
          key: street.streetCode,
          total: parseNumberWith2Fixed(street.total)
        }

        _.forEach(disposalSiteTrashName, (value, key) => {
          tmp[key] = parseNumberWith2Fixed(street[key])
        })

        return tmp
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.content {
  background-color: white;
  padding: 15px;
}
</style>

